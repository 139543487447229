import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Mert Ciflikli', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Mert Ciflikli is a software engineer who lives and works in Berlin.', // e.g: Welcome to my website
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  resume: 'https://drive.google.com/file/d/1aTvYpEtPk5pdrk6mXgt2pT7lEokAYXIG/view?usp=sharing', // if no resume, the button will not show up
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/mertciflikli/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/frankie303',
    },
    {
      id: nanoid(),
      name: 'soundcloud',
      url: 'https://soundcloud.com/frankie909',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://x.com/_frankie303',
    },
  ],
};
